import { IUrlFilterItem } from '@mg-platform/core/core-data-access'

export interface ICategoryData {
  id?: string
  name?: string
  queryParams?: IUrlFilterItem
  totalSales?: number
  totalSalesFormatted?: string
  percentage?: number
  percentageFormatted?: string
  fromDate?: Date
  toDate?: Date
}

export interface ICategoryMonth extends ICategoryData {
  monthYear: string
}

export interface ICategoryItem {
  id: string
  name: string
  months: ICategoryMonth[]
  total: ICategoryData
}

export interface ICategoryItemExtended {
  [key: string]: ICategoryData
}

export interface ICategorySummaryResponse {
  categories: ICategoryItem[]
  globalTotal: ICategoryMonth[]
}
