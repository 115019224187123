import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs'
import { CategoriesStateModel } from './categories.model'
import { CategoriesService } from '../../categories.service'
import { GetCategories } from './categories.actions'

@State<CategoriesStateModel>({
  name: 'categories'
})
@Injectable()
export class CategoriesState {
  constructor(private categoriesService: CategoriesService) {}

  @Selector()
  static response(state: CategoriesStateModel) {
    return state.response
  }

  @Action(GetCategories)
  getCategories(ctx: StateContext<CategoriesStateModel>, { payload }: GetCategories) {
    return this.categoriesService.getCategories(payload).pipe(
      tap((res) => {
        ctx.patchState({
          response: res
        })
      })
    )
  }
}
