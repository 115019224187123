<div class="w-full flex h-[100dvh] overflow-hidden">
  <div class="flex-1 relative bg-primary h-full hidden md:block">
    <div class="logo-section h-full flex items-center justify-center">
      @if (referralType === referralTypes.protractor) {
        <div class="p-lg max-w-[444px] flex flex-col items-center justify-center">
          <div class="text-2xl leading-2xl text-white">
            Stay ahead of the competition with real-time market insights!
          </div>
          <div class="w-full mt-[40px] flex items-center justify-between">
            <img width="187px" src="assets/images/icons/big-logo-white.svg" />
            <img width="187px" src="assets/images/protractor/new-logo.png" />
          </div>
        </div>
      } @else {
        <img width="284px" src="assets/images/icons/big-logo-white.svg" />
      }
    </div>

    <div
      class="absolute flex bottom-2xl left-2xl p-lg text-white cursor-pointer"
      (click)="themeModeService.changeDarkMode(isDarkMode ? 'light' : 'dark')"
    >
      <svg-icon [key]="isDarkMode ? 'dark-mode' : 'light-mode'" class="!text-lg"></svg-icon>
    </div>
  </div>
  <div class="flex-1 bg-contrast h-full overflow-auto">
    <div class="md:max-w-[390px] md:mx-auto h-full md:py-2xl md:px-lg">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
