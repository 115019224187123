export const ApplicationRoutes = {
  dashboard: 'dashboard',
  marketPricing: 'market-pricing',
  weeklyReports: 'weekly-reports',
  compSales: 'comp-sales',
  shops: 'shops',
  users: 'users',
  profile: 'profile',
  invitation: 'invitation/:token',
  enterprisePanel: 'enterprise-panel',
  autovitals: 'autovitals/:token',
  benchmark: 'benchmark',
  goals: 'goals',
  performanceCalendar: 'performance-calendar',
  publicWeeklySummary: 'weekly-summary/:token',
  samples: 'samples',
  reports: 'reports',
  notifications: 'notifications',
  categories: 'categories'
}
