import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  AggregatorType,
  ITableFilter,
  ITableFIlterNotifier,
  ITableFilterRule,
  TableColumnType,
  TableOperator
} from './table-filter.interface'
import { MatMenuTrigger } from '@angular/material/menu'
import { ISelectItem } from '@mg-platform/core/core-ui'
import {
  AbstractControl,
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { NumberOperators, StringOperators } from './table-filter.constant'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { PeriodType } from '@mg-platform/core/core-data-access'
import { Sort, SortDirection } from '@angular/material/sort'

@UntilDestroy()
@Component({
  selector: 'mg-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger

  @Input() filter: ITableFilter
  @Input() sort?: Sort
  @Input() onlySort = false
  @Input() rightAlign = false

  @Output() filterChanged = new EventEmitter<ITableFIlterNotifier>()

  sortDirection?: SortDirection
  tableColumnTypes = TableColumnType

  visibleListItems: ISelectItem[] = []
  form: UntypedFormGroup
  aggregatorControl: AbstractControl | null
  itemsControl: UntypedFormArray
  operators: ISelectItem[]
  defaultOperator: TableOperator
  aggregatorItems: ISelectItem[] = [
    {
      label: 'Match any',
      value: AggregatorType.Or
    },
    {
      label: 'Match all',
      value: AggregatorType.And
    }
  ]
  defaultAggregator: AggregatorType = AggregatorType.Or
  selectedDateInfo: IRangeDateInfo

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.visibleListItems = [...(this.filter.listItems ?? [])]

    switch (this.filter.columnType) {
      case TableColumnType.text:
        this.operators = StringOperators
        this.defaultOperator = TableOperator.Contains
        break

      case TableColumnType.number:
        this.operators = NumberOperators
        this.defaultOperator = TableOperator.Equals
        break

      case TableColumnType.checkbox:
      case TableColumnType.radio:
        this.defaultOperator = TableOperator.Equals
        break

      case TableColumnType.date:
        this.defaultOperator = TableOperator.Period
        this.defaultAggregator = AggregatorType.And
        break
    }

    this.form = this.fb.group({
      aggregator: [this.filter.aggregator],
      items: this.fb.array([]),
      searchText: [undefined]
    })

    this.aggregatorControl = this.form.get('aggregator')
    this.itemsControl = this.form.get('items') as UntypedFormArray

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        const trimValue = value?.trim().toLowerCase()
        if (trimValue && trimValue !== '') {
          this.visibleListItems =
            this.filter.listItems?.filter((el) => el.label.toLowerCase().includes(trimValue)) ?? []
        } else {
          this.visibleListItems = [...(this.filter.listItems ?? [])]
        }
      })
    this.updateForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sort'].currentValue && this.sort?.active === this.filter.key) {
      this.sortDirection = this.sort?.direction
    }
  }

  updateForm() {
    this.itemsControl.clear()
    this.aggregatorControl?.setValue(this.filter.aggregator ?? this.defaultAggregator)
    if (!this.filter?.items || this.filter.items.length < 1) {
      this.addNewRule()
    } else {
      for (const item of this.filter.items) {
        this.addNewRule(item)
      }
    }
  }

  onMenuClose(): void {
    setTimeout(() => {
      this.updateForm()
    }, 200)
  }

  public openMenu(): void {
    this.menu.openMenu()
  }

  addNewRule(item?: ITableFilterRule) {
    if (item?.extraValue && this.filter.columnType === TableColumnType.date) {
      this.selectedDateInfo = {
        periodType: item?.value ?? PeriodType.Custom,
        rangeDate: item.extraValue
      }
    }

    this.itemsControl.push(
      this.fb.group({
        operator: [item?.operator ?? this.defaultOperator],
        value: [item?.value, Validators.required],
        extraValue: [item?.extraValue]
      })
    )
  }

  clearFilters() {
    this.itemsControl.clear()
    this.addNewRule()
    this.filterChanged.emit({
      filter: { ...this.filter, items: [], columnType: this.filter.columnType }
    })
  }

  removeRule(index: number) {
    this.itemsControl.removeAt(index)
    if (this.itemsControl?.value?.length === 0) {
      this.addNewRule()
    }
  }

  onDateChange(
    valueControl: AbstractControl,
    extraValueControl: AbstractControl,
    event: IRangeDateInfo
  ) {
    this.selectedDateInfo = event
    if (this.selectedDateInfo.rangeDate?.from && this.selectedDateInfo.rangeDate?.to) {
      valueControl.setValue(event.periodType ?? PeriodType.Custom)
      extraValueControl.setValue({
        from: this.selectedDateInfo.rangeDate.from,
        to: this.selectedDateInfo.rangeDate.to
      })
    }
  }

  submit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      const newFilters: ITableFilter = this.form.value
      this.filterChanged.emit({
        filter: {
          ...this.filter,
          ...newFilters,
          columnType: this.filter.columnType
        }
      })
      this.menu.closeMenu()
    }
  }

  focusOnInput() {
    const input = document.getElementById(`${this.filter.key}input`)
    if (input) {
      input.focus()
    }

    const filtertop = document.getElementById(`${this.filter.key}filtertop`)
    if (filtertop) {
      filtertop.scrollIntoView()
    }
  }
}
