import { Injectable } from '@angular/core'
import { GetStates } from '@mg-platform/core/core-data-access'
import { GetAvailableWeeks } from '@mg-platform/reports/reports-data-access'

import { GetAllUserShops } from '@mg-platform/shops/shops-data-access'
import { GetUserPreferences } from '@mg-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { catchError, map, Observable, of, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserInfoGuard {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.store
      .dispatch([
        new GetAllUserShops(),
        new GetStates(),
        new GetAvailableWeeks(),
        new GetUserPreferences()
      ])
      .pipe(
        catchError(() => {
          return of(null)
        }),
        take(1),
        map(() => true)
      )
  }
}
