import { Sort } from '@angular/material/sort'
import { ISelectItem } from '@mg-platform/core/core-ui'

// #region Table Interfaces

export enum AggregatorType {
  And = 'And',
  Or = 'Or'
}

export enum TableOperator {
  'LessThan' = 'LessThan',
  'LessThanOrEqual' = 'LessThanOrEqual',
  'Equals' = 'Equals',
  'NotEquals' = 'NotEquals',
  'GreaterThanOrEqual' = 'GreaterThanOrEqual',
  'GreaterThan' = 'GreaterThan',
  'Contains' = 'Contains',
  'StartsWith' = 'StartsWith',
  'EndsWith' = 'EndsWith',
  'Period' = 'Period'
}

export enum TableColumnType {
  text = 'text',
  number = 'number',
  radio = 'radio',
  checkbox = 'checkbox',
  date = 'date'
}

export interface ITableFilterRule {
  operator: TableOperator
  value: any
  extraValue?: any
}

export interface ITableFilter {
  key: string
  label?: string
  columnType?: TableColumnType
  aggregator?: AggregatorType
  items?: ITableFilterRule[]
  listItems?: ISelectItem[]
  searchable?: boolean
}

export interface ITableFIlterNotifier {
  key?: string
  filter?: ITableFilter
  sort?: Sort
  shouldDelete?: boolean
}

export type TableFilters = { [key: string]: ITableFilter }

export interface ITableAction {
  sort?: Sort
  filters?: TableFilters
  search?: string
  page: number
}

export interface ITableActionRequest {
  sorts?: Sort[]
  filters?: ITableFilter[]
  search?: string
}

//#endregion

// #region Request Interfaces

export interface ITableFilterRequest {
  [key: string]: ITableFilter
}

export interface IPaginationFilteredOrderBy {
  property: string
  direction: 'Ascending' | 'Descending'
}

export interface IPaginationBaseRequest {
  orderBy?: IPaginationFilteredOrderBy[]
  top: number
  skip: number
}

export interface IPaginationFilteredRequest extends IPaginationBaseRequest {
  filters: ITableFilterRequest
}

export interface IPaginationFilteredResponse {
  count: number
}
//#endregion
